import { environment } from '../../../../environments/environment';

export class Constant {
  public static url_assets_images = 'assets/params/images/';
  public static url_assets_images_share = environment.domain;
  public static url_files_fmarket = environment.filesFmarket;
  public static url_logo_fmarket_share = Constant.url_assets_images_share +  'assets/share/share-logo.png'
  public static path_blog = 'blog';
  public static path_dashboard = '';
  public static path_keyword = 'tu-khoa';
  public static path_category = 'danh-muc';
  public static path_search_results = 'ket-qua-tim-kiem';
  public static path_share = "share";
  public static path_contribute_goal = "contribute-goal";
  public static path_contribute_goal_notice = "notice";
  public static path_contribute_goal_order = "order";

  public static  key_local_language = 'language';
  
  public static path_fqs = "help-center";
  public static url_fqs = '/help-center';
  public static url_qa_contact = '/help-center/contact';
  public static path_fqs_detail = 'qa-detail';


  public static url_blog = '/blog';
  public static path_blog_page = 'blog/';
  public static path_blog_category_partner = 'hoat_dong_doanh_nghiep';

  public static url_blog_dashboard = '/';
  public static url_blog_keyword = Constant.url_blog + '/'  + Constant.path_keyword;
  public static url_blog_category = Constant.url_blog + '/'  + Constant.path_category;
  public static url_blog_category_on_header = Constant.url_blog + '/' + Constant.path_category;
  public static url_blog_category_partner = Constant.url_blog + '/' + Constant.path_blog_category_partner;
  public static url_blog_search_results = Constant.url_blog + '/' + Constant.path_search_results;
  public static url_qa_search_results = Constant.url_fqs + '/' + Constant.path_search_results;

  // Route Fmarket

  public static path_fund = 'fund';
  public static path_gold = 'gold';
  public static path_old_home = 'home-dashboard';
  public static path_home = 'home';
  public static path_download_app = 'download-app';
  public static path_resource = 'res';
  public static path_issuers = 'issuers';
  public static path_general = 'general';
  public static path_support = 'support';
  public static path_contact = 'contact';
  public static path_contact_vn = 'lien-he';
  public static path_promotion = 'promotion';
  public static path_reward = 'reward';
  public static path_robo = 'robo';
  public static path_agency = 'agency';
  public static path_ftool = 'ftool';
  public static path_calculate_invest = 'cong-cu-tinh-lai-kep';
  public static path_performance_fund = 'hieu-suat-quy-mo';
  public static path_performance_fund_for_partner = 'hieu-suat-quy-mo-cho-doi-tac';
  public static path_gold_chart_dashboard = 'gia-vang-hom-nay';
  public static path_gold_chart_dashboard_1 = 'gia-vang';
  public static path_gold_chart_dashboard_2 = 'bieu-do-vang';
  public static path_image_upload= 'image_upload';
  public static path_landing_page_product = 'gioi-thieu-san-pham';
  public static path_landing_page_markerting = 'chuong-trinh-muc-tieu-doi';
  public static path_bank_interest_rate = 'lai-suat-ngan-hang';
  public static path_bank_interest_rate_old = 'bank-interest-rate';
  public static path_products_home = 'san_pham';
  public static path_funds_page = 'danh-sach-quy';
  public static path_gold_home = 'danh-sach-vang';
  public static path_our_story = 've-chung-toi';
  public static path_our_story_en = 'our-story';
  public static path_our_partner = 'doi-tac';
  public static path_our_partner_en = 'our-partner';
  public static path_detail_fund = 'quy';
  public static path_detail_gold = 'vang';
  public static path_detail_partner = 'doi-tac';
  public static path_auth = 'auth';
  public static path_account = 'account';
  public static path_active = "activation";
  public static path_login = 'login';
  public static path_forgot = "forgot";
  public static path_register = "register";
  public static path_register_issuer = "signUp/issuer";
  public static path_register_investor = 'signUp';
  public static path_ekyc = "ekyc";
  public static path_change_mail_success = "change-mail/success";
  public static path_change_mail_fail = "change-mail/fail";
  public static path_active_reset_pass = "reset-password";
  public static path_legal = 'legal';
  public static tab_legal_privacy = 'privacy';
  public static tab_legal_terms = 'terms';
  public static tab_legal_other_term = "other_term";
  public static path_fmarketPlatform = 'fmarketPlatform';
  public static path_introduce_agency = "refpartner";
  public static path_detail_portfolio = "portfolio";
  public static path_detail_products_home = "funds";
  public static tab_support_qa = "faq";
  public static tab_support_kn = "knowledge";
  public static tab_support_ua = "useApp";
  public static path_smart_portfolio = 'smart-portfolio';
  public static path_503 = '503';
  public static path_404 = '404';
  public static path_page = 'page';
  public static path_markerting = 'markerting';
  public static path_markerting_cafe_fund = 'cafe-quy-mo-la-gi';
  public static path_markerting_introduce_fmarket = 'chuong-trinh-gioi-thieu-fmarket';
  public static path_markerting_program_shared_goal = 'chuong-trinh-muc-tieu-doi';
  public static path_markerting_referral_program = 'chuong-trinh-gioi-thieu';
  public static path_markerting_robof_smart_portfolio = 'robof-smart-portfolio';
  public static path_markerting_shared_goal = 'muc-tieu-doi';
  public static path_markerting_smart_portfolio = 'danh-muc-dau-tu-thong-minh';
  public static path_markerting_what_is_opened_fund = 'quy-mo-la-gi-fmarket';

  public static url_home = '/' + Constant.path_home;
  public static url_ftool =  '/' +  Constant.path_calculate_invest;
  public static url_performance_fund =  '/' +  Constant.path_performance_fund;
  public static url_landing_page_product =  '/' +  Constant.path_landing_page_product;
  public static url_bank_interest_rate =   '/' +  Constant.path_bank_interest_rate;
  public static url_gold_chart_dashboard =   '/' +  Constant.path_gold_chart_dashboard;
  public static url_blog_page = Constant.url_blog;
  public static url_support =  '/' +  Constant.path_support;
  public static url_support_qa =   '/' +  Constant.path_support + '?tab=faq';
  public static url_contact =  '/' +  Constant.path_contact;
  public static url_promotion =  '/' +  Constant.path_promotion;
  public static url_products_home =   '/' +  Constant.path_products_home;
  public static url_detail_fund =  '/' +  Constant.path_detail_fund;
  public static url_funds_page =  '/' +  Constant.path_funds_page;
  public static url_gold_home =  '/' +  Constant.path_gold_home;
  public static url_detail_gold =  '/' +  Constant.path_detail_gold;
  public static url_detail_partner =  '/' +  Constant.path_detail_partner;
  public static url_our_story =  '/' + Constant.path_our_story;
  public static url_our_partner =  '/' +  Constant.path_our_partner;
  public static url_legal_privacy =  '/' +  Constant.path_legal + '?tab=' + Constant.tab_legal_privacy;
  public static url_legal_term =  '/' +  Constant.path_legal + '?tab=' + Constant.tab_legal_terms;
  public static url_fmarketPlatform =   '/' +  Constant.path_fmarketPlatform;
  public static url_download_app = '/' + Constant.path_download_app;
  public static url_login = environment.portalFmarket + Constant.path_login;
  public static url_register_investor =  '/' + Constant.path_auth + '/' + Constant.path_register_investor;
  public static url_register_investor_portal = environment.portalFmarket + Constant.path_register_investor;

  public static url_markerting = '/' + Constant.path_markerting;
  public static url_markerting_cafe_fund = Constant.url_markerting +'cafe-quy-mo-la-gi';
  public static url_markerting_introduce_fmarket = Constant.url_markerting +'chuong-trinh-gioi-thieu-fmarket';
  public static url_markerting_program_shared_goal = Constant.url_markerting +'chuong-trinh-muc-tieu-doi';
  public static url_markerting_referral_program = Constant.url_markerting +'chuong-trinh-gioi-thieu';
  public static url_markerting_robof_smart_portfolio = Constant.url_markerting +'robof_smart_portfolio';
  public static url_markerting_shared_goal = Constant.url_markerting +'muc-tieu-doi';
  public static url_markerting_smart_portfolio = Constant.url_markerting +'danh-muc-dau-tu-thong-minh';
  public static url_markerting_what_is_opened_fund = Constant.url_markerting +'quy-mo-la-gi-fmarket';


  // API 
  public static api_common = "/res/all";
  public static api_common_issuer = "/res/issuers";
  public static api_common_products_filter = "/res/products/filter";
  public static api_common_products_home_filter = "/res/products/home/filter";
  public static api_common_products_id = "/res/products/";
  public static api_common_following = "/home/subscribe";
  public static api_user_support_information = "/res/support-information";
  public static api_common_text_home = "/res/cms-block";
  public static api_common_calcualte_invest = "/tools/profit-investment";
  public static api_get_data_chart = "/res/product/get-nav-history";
  public static api_get_data_bank_interest = "/res/bank-interest-rate";
  public static api_get_data_categories_qa = "/qa/categories";
  public static api_get_product_code = "/home/product/";
  public static api_get_partner_path = "/home/partner/";
  public static api_get_partner_list = "/home/partners/";
  //Type cms text
  public static CMS_QA = "QA";
  public static CMS_USER_GUIDE = "USER_GUIDE";
  public static CMS_KNOWLEDGE = "KNOWLEDGE";
  public static CMS_HEADER = "HEADER";
  public static CMS_PORTFOLIO = "PORTFOLIO";
  public static CMS_GOOGLE_MAP = "GOOGLE_MAP";
  public static CMS_PLATFORM = "PLATFORM";
  public static CMS_FMARKET_PLATFORM = "FMARKET_PLATFORM";
  public static CMS_PLATFORM_TITLE = "PLATFORM_TITLE";
  public static CMS_PLATFORM_ICON = "PLATFORM_ICON";
  public static CMS_PLATFORM_CONTENT = "PLATFORM_CONTENT";
  public static CMS_PROCESS_INVESTOR = "PROCESS_INVESTOR";
  public static CMS_PROCESS_ISSUER = "PROCESS_ISSUER";
  public static CMS_HOWITWORK = "HOWITWORK";
  public static CMS_COMMON = "COMMON";
  public static CMS_COMMON_PHONE = "COMMON_PHONE";
  public static CMS_COMMON_EMAIL = "COMMON_EMAIL";
  public static CMS_LOCATION = "LOCATION";
  public static CMS_LEGAL_TERMS = "TERM";
  public static CMS_LEGAL_PRIVACY = "PRIVACY";
  public static CMS_LEGAL_DISCLOSURE = "DISCLOSURE";
  public static CMS_SLOGAN = "SLOGAN";
  public static CMS_INVESTOR_SLOGAN = "INVESTOR_SLOGAN";
  public static CMS_ISSUER_SLOGAN = "ISSUER_SLOGAN";
  public static CMS_ISSUER_TERM = "ISSUER_TERM";
  public static CMS_ISSUER_PRIVACY = "ISSUER_PRIVACY";
  public static CMS_ISSUER_LICENSE = "ISSUER_LICENSE";
  public static CMS_ISSUER_FAQ = "ISSUER_FAQ";
  public static CMS_INVESTOR_TERM = "INVESTOR_TERM";
  public static CMS_INVESTOR_PRIVACY = "INVESTOR_PRIVACY";
  public static CMS_INVESTOR_LICENSE = "INVESTOR_LICENSE";
  public static CMS_INVESTOR_FAQ = "INVESTOR_FAQ";
  public static CMS_OUR_STORY = "OUR_STORY";
  public static CMS_OUR_STORY_CONTENT = "OUR_STORY_CONTENT";
  public static CMS_OUR_STORY_HEADER = "OUR_STORY_HEADER";
  public static CMS_TRANSACTION = "TRANSACTION";
  public static CMS_HOTLINE = "HOTLINE";
  public static CMS_TIME = "TIME";
  public static CMS_QA_ISSUER = "QA_ISSUER";
  public static CMS_QA_GOLD = "QA_GOLD";
  public static CMS_LICENSE = "LICENSE";
  public static CHAT = "CHAT";

  public static icon_url_host = "/assets/images/icon/";
  public static icon_files = Constant.icon_url_host + "files.svg";
  public static icon_down = Constant.icon_url_host + "download.png";


  public static checkTablet() {
    if (typeof window !== 'undefined') {
      let screen = window.screen.width;
      return screen >= 768 && screen <= 1024;
    }
    return false;
  }
  
  public static checkMobile() {
    if (typeof window !== 'undefined') {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor);
      return window.screen.width < 768 ? check : false;
    }
    return false;
  }
  public static url_blog_pages = '/' + Constant.path_blog_page;

  public static checkAndroid() {
    if (typeof window !== 'undefined') {
      let userAgent = navigator.userAgent || navigator.vendor;
      return /android/i.test(userAgent);
    }
    return false;
  }

  public static checkIOS() {
    if (typeof window !== 'undefined') {
      let userAgent = navigator.userAgent || navigator.vendor;
      return /iPad|iPhone|iPod/.test(userAgent);
    }
    return false;
  }
  public static ANROID_APP =
    "https://play.google.com/store/apps/details?id=com.fincorp.fmarket";
  public static DEEPLINK_ANROID_APP = "market://details?id=com.fincorp.fmarket";
  public static IOS_APP =
    "https://apps.apple.com/vn/app/fmarket-smart-invest/id1585628917";
  public static DEEPLINK_IOS_APP = "https://itunes.apple.com/app/id1585628917";
  public static DEEPLINK_APP_TARGET = "fmarket://target";
  public static DEEPLINK_APP_ROBO_SUGGEST = "fmarket://robo_suggest";
  public static DEEPLINK_APP_REFUND = "fmarket://refund/orderId/";
  public static DEEPLINK_APP_PORTFOILIO = "fmarket://portfolio";
  public static DEEPLINK_APP_MARKET_FUND = "fmarket://market_ccq";
  public static DEEPLINK_APP_MARKET_GOLD = "fmarket://market_gold";
}
